import React from 'react';
import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import styled from 'styled-components';
import Header from '../components/Header';
import FoundingSupporters from '../components/FoundingSupporters';
import CalloutAction from '../components/CallOutAction';
import SEO from '../components/SEO';

const CallOutBannerStyles = styled.div`
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
  font-family: 'Public_Sans';
  font-size: clamp(18px, 3vw, 24px);
  background-color: var(--darkPurple);
  color: var(--white);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    width: clamp(75vw, 50%, 1000px);
    margin: 0 auto;
    line-height: clamp(1.2rem, 7vw, 2.5rem);
  }
  strong {
    font-weight: 600;
  }
`;

const MainStyles = styled.main`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: clamp(25px, 10vw, 50px);
  margin-bottom: clamp(25px, 10vw, 100px);
  @media (max-width: 1024px) {
    max-width: 80vw;
  }
  .download-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

function CalloutBanner({ text }) {
  return (
    <CallOutBannerStyles>
      <p>{parse(text)}</p>
    </CallOutBannerStyles>
  );
}

export default function HomePage({ location, data }) {
  const homepage = data.homepage.nodes[0];
  // const actions = data.actions.nodes;
  const supporters = data.supporters.nodes;
  return (
    <>
      <SEO location={location}>
        <meta
          property="og:title"
          content="One Source Coalition"
          key="ogtitle"
        />
      </SEO>
      <Header />
      <CalloutBanner text={homepage.callOut} />
      <MainStyles>
        <p>{homepage.solutionIntro}</p>
        <CalloutAction specialStyles />
        <FoundingSupporters supporters={supporters} />
      </MainStyles>
    </>
  );
}

export const query = graphql`
  query HomepageQuery {
    homepage: allSanityHomepage {
      nodes {
        callOut
        challenge
        solutionIntro
      }
    }
    supporters: allSanitySupporter(filter: { active: { eq: true } }) {
      nodes {
        id
        title
        logo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        active
      }
    }
  }
`;
