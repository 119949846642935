import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const CallOutActionStyles = styled.div`
  /* width: clamp(75vw, 50%, 1000px); */
  text-align: center;
  a:hover {
    /* background: var(--darkGreenDarker); */
    text-decoration: underline;
    color: #fff;
  }
  .btnLink {
    font-family: Public_Sans;
    font-weight: 800;
    letter-spacing: 1px;
    display: inline-block;
    margin-top: clamp(1.5rem, 7vw, 3rem);
    font-size: clamp(18px, 4vw, 26px);
    line-height: 30px;
    padding: 20px 25px;
    background: var(--darkPurple);
    color: var(--white);
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      width: 70%;
    }
  }
  .btnLink.lowerButtonStyles {
    display: block;
    width: 40%;
    margin-top: 4rem;
    text-align: center;
    @media (max-width: 1439px) {
      width: 50%;
    }
    @media (max-width: 1023px) {
      width: 70%;
    }
    @media (max-width: 767px) {
      width: 80%;
    }
  }
`;

export default function CalloutAction({ specialStyles }) {
  return (
    <StaticQuery
      query={graphql`
        query CalloutQuery {
          callout: allSanityHomepage {
            nodes {
              callOut
              callToAction
              contactEmail
              actionPlan {
                asset {
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const callout = data.callout.nodes[0];
        return (
          <CallOutActionStyles>
            <a
              href={callout.actionPlan.asset.url}
              target="_new"
              className={`btnLink${specialStyles ? ' lowerButtonStyles' : ''}`}
            >
              Download the Statement of Solutions
            </a>
            {callout.callToAction !== null ? (
              <a className="btnLink" href={`mailto:${callout.contactEmail}`}>
                {callout.callToAction}
              </a>
            ) : (
              ''
            )}
          </CallOutActionStyles>
        );
      }}
    />
  );
}
